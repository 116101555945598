import { Fragment, useEffect } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from 'react';

import * as links from "../../dermuscomponents/src/Constants/Links";
import { STALE_SERVE } from "../../dermuscomponents/src/Constants/Message";
import { setStaleActionCreator } from "../../dermuscomponents/src/store/actionCreators/patientManagementActionCreator";
import { addSnackbar } from "../../dermuscomponents/src/UI/Snackbar/Snackbar";
import { Box } from "@mui/material";
import { isDemo } from "../../dermuscomponents/src/utility/functions";
import { USB_SETTINGS_URL } from "../../dermuscomponents/src/Constants/Links";
import { SETTINGS_URL } from "../../dermuscomponents/src/Constants/Links";
import { CALIBRATION_URL } from "../../dermuscomponents/src/Constants/Links";
import { USER_PREFERENCES_URL } from "../../dermuscomponents/src/Constants/Links";

const Login = lazy(() => import("../../dermuscomponents/src/Components/Authentication/Login"));
const ForgotPassword = lazy(() => import("../Authentication/ForgotPassword"));
const Patients = lazy(() => import("../../dermuscomponents/src/Components/PatientManagement/Patients"));
const Patient = lazy(() => import("../../dermuscomponents/src/Components/PatientManagement/Patient/Patient"));
const Lesion = lazy(() => import("../../dermuscomponents/src/Components/PatientManagement/Lesions/Lesion"));
const RecordWrapper = lazy(() => import("../PatientManagement/Record/RecordWrapper"));
const AddAndModifyPatient = lazy(() => import("../../dermuscomponents/src/Components/PatientManagement/Patient/AddAndModifyPatient"));
const AddLesion = lazy(() => import("../../dermuscomponents/src/Components/PatientManagement/Lesions/AddLesion"));

const About = lazy(() => import("../../dermuscomponents/src/Components/About"));
const Settings = lazy(() => import("../Authentication/Settings"));
const VerifyEmail = lazy(() => import("../Authentication/VerifyEmail"));
const Signup = lazy(() => import("../Authentication/Signup"));
const UserPreferences = lazy(() => import("../../dermuscomponents/src/Components/Authentication/UserPreferences"));
const PrimarySpinner = lazy(() => import("../../dermuscomponents/src/UI/Spinner/PrimarySpinner"));

const NewFeatureBox = lazy(() => import("../../dermuscomponents/src/UI/Other/NewFeatureBox"));
const Logout = lazy(() => import("../../dermuscomponents/src/Components/Authentication/Logout"));
const Feedback = lazy(() => import("../../dermuscomponents/src/Components/Other/Feedback"));



const PatientManagment = () => {
  //Redux dispatch
  const dispatch = useDispatch();
  //Stale cache
  const stale = useSelector((state) => state.patMan.stale);
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  //Location hook
  const location = useLocation();
  //history
  const history = useHistory();
  useEffect(() => {
    if (isDemo()) {
      if ([USB_SETTINGS_URL, SETTINGS_URL, CALIBRATION_URL, USER_PREFERENCES_URL].includes(location.pathname)) {
        history.replace("")
      }
    }// eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (stale) {
      addSnackbar({
        message: STALE_SERVE,
        onClose: () => dispatch(setStaleActionCreator(false)),
        preventDuplicate: true
      })
    }// eslint-disable-next-line
  }, [stale])

  //Loged in
  if ((session === null || location.pathname.endsWith(links.VERIFY_EMAIL_URL)) && !isDemo()) {
    return (
      <Fragment>
        <Switch>
          <Route path={links.LOGOUT_URL} component={Logout} />
          <Route path={links.FORGOT_PSW_URL} exact component={ForgotPassword} />
          <Route path={links.LOGIN_URL} component={Login} />
          <Route path={links.SIGN_UP_URL} exact component={Signup} />
          <Route path={links.VERIFY_EMAIL_URL} exact component={VerifyEmail} />

          <Redirect
            from={links.ADD_PATIENT_URL}
            to={links.LOGIN_URL + location.pathname +
              location.search +
              location.hash}
          />

          <Redirect
            from={links.SETTINGS_URL}
            to={links.LOGIN_URL + location.pathname +
              location.search +
              location.hash}
          />

          <Redirect
            from={links.USER_PREFERENCES_URL}
            to={links.LOGIN_URL + location.pathname +
              location.search +
              location.hash}
          />
          <Redirect
            path={links.USB_SETTINGS_URL}
            to={links.LOGIN_URL + location.pathname +
              location.search +
              location.hash}
          />
          <Redirect
            to={links.LOGIN_URL + location.pathname +
              location.search +
              location.hash}
          />
        </Switch>
      </Fragment>
    );
  } else if (session === undefined || (isDemo() && (session && !session.restored))) {//Not read yet
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
      <PrimarySpinner />
    </Box>
  } else {
    return (
      <Fragment>
        {!isDemo() && <NewFeatureBox />}
        <Switch>
          <Route
            path={links.VERIFY_EMAIL_URL}
            exact
            component={VerifyEmail}
          />

          <Route
            path={links.EDIT_PATIENT_URL + "/:patientId"}
            exact
            component={AddAndModifyPatient}
          />
          <Route path={links.ABOUT_URL} exact component={About} />
          <Route path={links.LOGOUT_URL} component={Logout} />
          <Route path={links.SETTINGS_URL} exact component={Settings} />
          <Route path={links.ADD_PATIENT_URL} exact component={AddAndModifyPatient} />
          <Route path={links.USER_PREFERENCES_URL} exact component={UserPreferences} />

          <Route path={links.FEEDBACK_URL} exact component={Feedback} />
          <Redirect
            from={links.LOGIN_URL}
            to={
              location.pathname.substring(links.LOGIN_URL.length) +
              location.search +
              location.hash
            }
          />
          <Redirect
            from={links.PATIENTS_URL}
            to={""}
          />
          <Redirect
            from={`${links.PATIENT_URL}/:patientId${links.LESION_URL}/:lesionId${links.RECORD_URL}/:recordId`}
            exact
            to={"/:patientId/:lesionId/:recordId"}
          />
          <Redirect
            from={`${links.PATIENT_URL}/:patientId${links.LESION_URL}/:lesionId`}
            exact
            to={"/:patientId/:lesionId"}
          />
          <Redirect
            from={`${links.PATIENT_URL}/:patientId`}
            exact
            to={"/:patientId"}
          />
          <Route
            path={"/:patientId" + links.ADD_LESION_URL}
            exact
            component={AddLesion}
          />
          <Route
            path={
              "/:patientId" +
              "/:lesionLocalId" +
              "/:recordLocalId"
            }
            exact
            component={RecordWrapper}
          />
          <Route
            path={
              "/:patientId" +
              "/:lesionLocalId"
            }
            exact
            component={Lesion}
          />
          <Route
            path={"/:patientId"}
            exact
            component={Patient}
          />

          <Route path={""} exact component={Patients} />
          <Redirect to={""} />
        </Switch>
      </Fragment>
    );
  }

};

export default PatientManagment;