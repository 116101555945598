import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as links from "./dermuscomponents/src/Constants/Links";
import { closeSnackbar } from 'notistack'
import dermusAxios from "./dermuscomponents/src/API/dermusAxios"

import PatientManagment from "./Components/PatientManagement/PatientManagment";
import Layout from "./dermuscomponents/src/UI/Container/Layout";
import useEventListener from "./dermuscomponents/src/hooks/useEventListener";
import { REDICERTION_PATH_AUTH_LOCAL_STORAGE, SSO_LOCAL_STORAGE, USER_SIGNED_IN_LOCAL_STORAGE } from "./dermuscomponents/src/Constants/Constants";
import {
  CONNECTIONS_LOST_OR_SKINAID_DOWN,
  CONNECTION_ESTABLISHED,
} from "./dermuscomponents/src/Constants/Message";
import { Fragment, useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import {
  manifestActionCreator,
  viewActionCreator,
} from "./dermuscomponents/src/store/actionCreators/patientManagementActionCreator";


import { dermusTheme } from "./dermuscomponents/Themes/dermusTheme";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import useIsOnline from "./dermuscomponents/src/API/useIsOnline";
import { checkUserValidity, logout } from "./dermuscomponents/src/API/authenticationAPI";
import { ICD_TOKEN_EP, USER_PREFERENCES_EP, CREATE_DEMO_DATA_EP } from "./dermuscomponents/src/API/endPoints";


import * as ECT from "@whoicd/icd11ect";
import { DEMO_DATA_IN_LOCAL_STORAGE, ICDSettings } from "./dermuscomponents/src/Constants/Constants";
import useHttp, { GET, POST, } from "./dermuscomponents/src/API/useHttp";
import { addUserPreferencesActionCreator } from "./dermuscomponents/src/store/actionCreators/authenticationActionCreator";
import { addSnackbar } from "./dermuscomponents/src/UI/Snackbar/Snackbar";

import Grow from '@mui/material/Grow';

import { SnackbarProvider } from 'notistack'
import { useRef } from "react";
import { dispatchDemoSession, generateDemoToken, verifyToken } from "./dermuscomponents/src/utility/utility";
import { isDemo } from "./dermuscomponents/src/utility/functions";
import { FETCH_STORAGE_PREFIX } from "./dermuscomponents/src/API/axiosUtil";
import { removeStorageItem, restoreItem, storeItem } from "./dermuscomponents/src/API/localStorageAPI";
import { Suspense } from "react";
import PrimarySpinner from "./dermuscomponents/src/UI/Spinner/PrimarySpinner";
import useInstallApp from "./dermuscomponents/src/hooks/useInstallApp";



const App = () => {
  const history = useHistory()
  //install app
  const installApp = useInstallApp({})
  //Path
  const { pathname, search } = useLocation();
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  //User preferences
  const userPreferences = useSelector((state) => state.auth.userPreferences);
  //Loggidin user
  const userLoggedIn = useSelector((state) => state.auth.signedInUser);

  //Discconected snackbar erf
  const disconnectedRef = useRef()
  //Dispatch
  const dispatch = useDispatch();
  //Connection change snakcbar is open or not
  const [openConnectionChangeSnackBar, setOpenConnectionChangeSnackBar] = useState(false);
  //User from redirection
  const [redirectedUser] = useState(new URLSearchParams(search).get("userToken") === null ? null : verifyToken(new URLSearchParams(search).get("userToken"))?.user)
  const [forceLogout] = useState(new URLSearchParams(search).get("logout") === null ? null : verifyToken(new URLSearchParams(search).get("logout")))


  useEffect(() => {
    if (forceLogout && pathname === links.SIGN_UP_URL) {
      if (forceLogout.state && forceLogout?.exp * 1000 > new Date().getTime()) {
        if (forceLogout.state) {
          const queryParams = new URLSearchParams(search)
          if (queryParams.has('logout')) {
            queryParams.delete('logout')
            history.replace({
              search: queryParams.toString(),
            })
          }
          logout(dispatch, session, false, true, true);
        }
      }
    }// eslint-disable-next-line 
  }, [forceLogout])

  //Is online
  const { isOnline, forcePing } = useIsOnline();


  //Get user preferences
  const [
    ,
    sendRequestUserPreferences,
    ,
    pendingUserPreferences
  ] = useHttp();

  //Is fullscreen
  const [isFullscreen, setIsFullScreen] = useState(!!document.fullscreenElement)
  //Create demo data 
  const [
    ,
    sendRequestCreateDemoData
  ] = useHttp();
  //Set orientation
  const setOrientation = (orientation) => {
    if (window?.screen?.orientation?.lock) {
      window?.screen?.orientation
        ?.lock(orientation.includes('portrait') ? 'portrait' : 'landscape')
        .then(() => {
          console.log(orientation.includes('portrait') ? 'Portrait mode locked.' : 'Landscape mode locked.')
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }
  useEffect(() => {
    if (redirectedUser || redirectedUser === undefined) {
      const queryParams = new URLSearchParams(search)
      if (queryParams.has('userToken')) {
        queryParams.delete('userToken')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
    //eslint-disable-next-line
  }, [redirectedUser])

  //go to demo
  useEffect(() => {
    if (!isDemo()) {
      if (pathname.includes(links.CAPTURE__DEMO_URL) ||
        pathname.includes(links.CAPTURE_DEMO_URL) ||
        pathname.includes(links.DEMO_URL + links.CAPTURE_URL)) {
        window.location.href = links.DEMO_CAPTURE_FULL_URL
      } else if (pathname.includes(links.DEMO_URL)) {
        window.location.href = links.DEMO_SKINAID_FULL_URL
      }
    }
  }, [pathname])
  useEffect(() => {
    if (isDemo()) {
      const demoData = JSON.parse(sessionStorage.getItem(DEMO_DATA_IN_LOCAL_STORAGE));

      if (redirectedUser) {
        if (demoData && redirectedUser === demoData.payload.sub) {
          if (demoData?.payload?.exp * 1000 > new Date().getTime()) {
            dispatchDemoSession(dispatch, demoData.token, demoData.payload, true)
          } else {
            const newDemoToken = generateDemoToken(redirectedUser)
            dispatchDemoSession(dispatch, newDemoToken.token, newDemoToken.payload, true)
          }
          for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i)?.startsWith(FETCH_STORAGE_PREFIX)) {
              removeStorageItem(localStorage.key(i))
            }
          }
        } else {
          const newDemoToken = generateDemoToken(redirectedUser)
          dispatchDemoSession(dispatch, newDemoToken.token, newDemoToken.payload, true)
          sessionStorage.setItem(DEMO_DATA_IN_LOCAL_STORAGE, JSON.stringify({ token: newDemoToken.token, payload: newDemoToken.payload }))
        }
      } else {
        if (demoData && demoData?.payload?.sub) {
          if (demoData?.payload?.exp * 1000 > new Date().getTime()) {
            dispatchDemoSession(dispatch, demoData.token, demoData.payload, true)
          } else {
            const newDemoToken = generateDemoToken(demoData.payload.sub)
            dispatchDemoSession(dispatch, newDemoToken.token, newDemoToken.payload, true)
          }
        } else {
          const newDemoToken = generateDemoToken()
          dispatchDemoSession(dispatch, newDemoToken.token, newDemoToken.payload, false)
        }
      }
    }// eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (session) {
      if (isDemo()) {
        if (!session.restored) {
          sendRequestCreateDemoData(CREATE_DEMO_DATA_EP, POST, true, (res) => {
            const demoToken = session.getIdToken().getJwtToken()
            const payload = session.getIdToken().payload
            dispatchDemoSession(dispatch, demoToken, payload, true)
            sessionStorage.setItem(DEMO_DATA_IN_LOCAL_STORAGE, JSON.stringify({ token: demoToken, payload }))
            return res
          }
          )
        }
      } else {
        const noSSO = !restoreItem(SSO_LOCAL_STORAGE)
        if (noSSO) {
          logout(dispatch, session)
        } else {
          if (redirectedUser && redirectedUser !== session.getIdToken().payload.sub) {
            logout(dispatch, session)
          } else if (redirectedUser) {
            for (let i = 0; i < localStorage.length; i++) {
              if (localStorage.key(i)?.startsWith(FETCH_STORAGE_PREFIX)) {
                removeStorageItem(localStorage.key(i))
              }
            }
          }
        }
      }
    } else if (session === null) {
      if (redirectedUser && !isDemo()) {
        storeItem(REDICERTION_PATH_AUTH_LOCAL_STORAGE, window.location.pathname + window.location.search)
        window.location.href = links.SSO_LOGIN_FULL_URL
      }
    }//eslint-disable-next-line
  }, [session])

  //Handle exit fullscreen other method then fullscreen button
  useEffect(() => {
    const handleFullScreenChange = () => { setIsFullScreen(s => !s); }
    window.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => window.removeEventListener("fullscreenchange", handleFullScreenChange);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const pathItems = pathname.split("/").filter(item => item !== "")
    const pathItemsNotNan = pathItems.map(item => parseInt(item)).filter(item => !isNaN(item))
    if (pathname.includes("capture") || (pathItems.length === 3 && pathItemsNotNan.length === 3)) {
      setOrientation('landscape');
    } else {
      setOrientation('portrait');
    }
  }, [pathname, isFullscreen]);


  useEffect(() => {
    if (isOnline.status !== isOnline.prev) {
      setOpenConnectionChangeSnackBar(true);
    }
  }, [isOnline]);

  useEffect(() => {
    if (openConnectionChangeSnackBar) {
      if (isOnline.status) {
        if (disconnectedRef.current) {
          closeSnackbar(disconnectedRef.current)
        }
        setOpenConnectionChangeSnackBar(false)
        addSnackbar({
          message: CONNECTION_ESTABLISHED,
          autoHideDuration: 6000
        })
      } else {
        setOpenConnectionChangeSnackBar(false)
        disconnectedRef.current = addSnackbar({
          message: CONNECTIONS_LOST_OR_SKINAID_DOWN,
          persist: true,
          action: action
        })
      }

    }// eslint-disable-next-line
  }, [openConnectionChangeSnackBar])
  useEffect(() => {
    if (userPreferences === null && session && !pendingUserPreferences) {
      sendRequestUserPreferences(USER_PREFERENCES_EP, GET, true, (d) => { dispatch(addUserPreferencesActionCreator(d)) })
    }// eslint-disable-next-line
  }, [userPreferences, session])

  useEffect(() => {
    if (session) {
      const myCallbacks = {
        getNewTokenFunction: async () => {
          try {
            const resp = await dermusAxios.get(ICD_TOKEN_EP, {
              headers: {
                Authorization: session.getAccessToken().jwtToken,
              },
            });
            return resp.data.access_token;
          } catch (e) { }
        },
      };
      ECT.Handler.configure(ICDSettings, myCallbacks);
      dispatchEvent(new Event("load"));
    }
  }, [session])

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/manifest.json`)
      .then((r) => r.json())
      .then((data) => {
        dispatch(manifestActionCreator(data));
      }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onerror = function (error, url, line) {
      console.log('ERR:' + error + ' URL:' + url + ' L:' + line);
    };
  }, [])


  useEventListener("storage", (event) => {
    if (!event.key) {
      checkUserValidity(dispatch);
      return;
    }
    if (event.key.localeCompare(USER_SIGNED_IN_LOCAL_STORAGE) === 0) {
      if (
        !localStorage.getItem(USER_SIGNED_IN_LOCAL_STORAGE) ||
        !userLoggedIn
      ) {
        checkUserValidity(dispatch);
      }
    }
  });
  useEffect(() => {
    dispatch(viewActionCreator({ isMobileOrTablet: isTablet || isMobile }));
    // eslint-disable-next-line
  }, []);

  const action = (
    <Fragment>
      {!isOnline.status && (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={forcePing}
        >
          <SyncIcon fontSize="small" />
        </IconButton>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          if (disconnectedRef.current) {
            closeSnackbar(disconnectedRef.current)
          }
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );
  return (
    <ThemeProvider theme={dermusTheme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider maxSnack={3} TransitionComponent={Grow} />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Layout>{installApp}
          <div style={{ paddingBottom: "0px" }}>
            <Suspense fallback={<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}><PrimarySpinner /></Box>}>
              <Route path="/" component={PatientManagment} />
            </Suspense>

          </div>
        </Layout>
      </Switch>

    </ThemeProvider >
  );
};


export default App;
